<template>
  <div class="main-wrap" >
    <h3>{{$store.state.userInfo.examName}}</h3>
    <!-- <div class="score-bg" :style="{height: ImgHeight + 'px'}"> -->
    <div class="score-bg">
      <img ref="ImgBg" :src="$store.state.userInfo.resultScore.pass ? PassBg : FailBg" alt="">
      <p v-show="scoreShow" class="score" :class="{'score-Fail' : !$store.state.userInfo.resultScore.pass}">{{$store.state.userInfo.resultScore.totalScore}}<span >分</span> </p>
    </div>
    <div class="tabel-box" v-show="scoreShow && scoreList.length > 1">
      <div class="tr-item"  v-for="(item,index) in scoreList" :key="index">
        <p class="name"><img :src="scoreImg[index].img" alt="">{{item.name}}<span></span></p>
        <p class="score">{{item.score}}分</p>
      </div>
      <!-- 考试成绩 -->
      <!-- <div class="tr-item">
        <p class="name"><img src="@/assets/image/score5.png" alt="">考试成绩</p>
        <p class="score">{{$store.state.userInfo.resultScore.examScore}}分</p>
      </div> -->
      <!-- 总成绩 -->
      <div class="tr-item">
        <p class="name"><img src="@/assets/image/score6.png" alt="">总成绩</p>
        <p class="score">{{$store.state.userInfo.resultScore.totalScore}}分</p>
      </div>
    </div>
    <p v-show="scoreShow" class="tips">温馨提示：该考试成绩后续可在认证查询——成绩查询中查询。</p>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      PassBg: require('@/assets/image/score-bg1.png'),  // 通过时分数框
      FailBg: require('@/assets/image/score-bg2.png'),  // 未通过时分数框
      scoreImg: [
        { img: require('@/assets/image/score1.png') },
        { img: require('@/assets/image/score2.png') },
        { img: require('@/assets/image/score3.png') },
        { img: require('@/assets/image/score4.png') },
        { img: require('@/assets/image/score5.png') }
      ],
      scoreShow:true,  // 控制分数显示
      ImgHeight: 0,  // 分数图片大小
      scoreSize: 55, // 分数文字大小
      scoreSize2: 40, // 分数文字大小
      screenWidth: null, //屏幕尺寸
      scoreList:[],
      isPass: true, // 是否通过
    }
  },
  // 事件处理器
  methods: {
    
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.scoreList = JSON.parse(this.$store.state.userInfo.resultScore.scoreRules)
  },
  // 生命周期-实例挂载后调用
  mounted () {
    setTimeout(()=>{  // 实时计算出图片的高度
      this.scoreShow = true
      this.ImgHeight = this.$refs.ImgBg.height
      this.screenWidth = document.body.clientWidth
      // 动态改变分数文字大小
      if(850 < this.screenWidth && this.screenWidth < 1300){
        this.scoreSize = this.screenWidth / 20 - 10
        this.scoreSize2 = this.screenWidth / 20 - 19
      } else if(this.screenWidth <= 850) {
        this.scoreSize = 30.5
        this.scoreSize2 = 15.5
      }
      window.onresize = () => {   //屏幕尺寸变化就重新赋值
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
    },50)
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    screenWidth(){ // 监听页面大小变化
      if(this.screenWidth < 1200 && this.screenWidth > 850){
        this.scoreSize = this.screenWidth / 20 - 10
        this.scoreSize2 = this.screenWidth / 20 - 19
      }
      this.ImgHeight = this.$refs.ImgBg.height // 图片高度
    }
  }
}
</script>

<style lang="less" scoped>
  .main-wrap {
    >h3{
      font-size: 32px;
      font-weight: 500;
      color: #000000;
    }
    .score-bg{
      width:316px;
      height: 240px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img{
        width: 100%;
        height: 100%;
        max-width: 316px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .score{
        position: relative;
        right: 8px;
        bottom: 10px;
        font-size: 55px;
        color: #2D74DD;
        font-weight: 600;
        span{
          font-weight: 400;
          font-size: 42px;
        }
      }
      .score-Fail{
        color: #747474;
      }
    }
    .tabel-box{
      width: 70%;
      margin: 40px auto 0;
      display: flex;
      border-radius: 8px;
      overflow: hidden;
      .tr-item{
        width: 100%;
        text-align: center;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        .name{
          background: #F2F2F2;
          height: 48px;
          line-height: 48px;
          color: #666666;
          font-weight: 400;
          img{
            margin-right: 4px;
            position: relative;
            top: -1px;
          }
          span{
            color: #333333;
            font-weight: 500;
          }
        }
        .score{
          font-size: 14px;
          background: #F8F9FA;
          height: 60px;
          line-height: 60px;
          color: #2D74DD;
        }
        
      }
    }
    .tips{
      margin-top: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  @media screen and (max-width: 1100px) {
    .main-wrap{
      >h3{
        font-size: 28px;
      }
      .score-bg{
        width:286px;
        height: 210px;
        .score{
          font-size: 45px;
          span{
            font-weight: 400;
            font-size: 32px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .main-wrap{
      >h3{
        font-size: 24px;
      }
      .score-bg{
        width:256px;
        height: 180px;
        .score{
          font-size: 40px;
          span{
            font-weight: 400;
            font-size: 28px;
          }
        }
      }
    }
  }
</style>
